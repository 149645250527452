.toggle_button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 26px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  margin-left: 30px;
}

.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  width: 30px;
  height: 4px;
  background: #ffffff;
}
