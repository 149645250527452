.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1b242f;
  height: 65px;
  box-shadow: 2px 0px 7px rgba(0, 0, 7, 0.5);
  margin-bottom: 20px;
  z-index: 100;
}

.toolbar_logo {
  margin-left: 1rem;
  text-transform: uppercase;
}

.toolbar_logo a {
  color: #e31b6d;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 0 1rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation {
  display: flex;
  height: 100%;
  align-items: center;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 0 0.5rem;
  margin-right: 20px;
  font-size: 1.2rem;
}

.toolbar_navigation_items a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
  color: #e31b6d;
}

@media (max-width: 768px) {
  .toolbar_navigation_items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar_toggle_button {
    display: none;
  }
}
