.contact {
  height: 100vh;
  padding-top: 100px;
  background-color: #353635;
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.contact_header {
  color: #ffffff;
}

.contact_links {
  text-align: center;
  margin-top: 150px;
}

.contact_github {
  color: #ffffff;
  text-decoration: none;
  margin-right: 40px;
  font-size: 36px;
}

.contact_github:hover {
  color: #e31b6d;
}

.contact_linkedin {
  color: #ffffff;
  text-decoration: none;
  font-size: 36px;
}

.contact_linkedin:hover {
  color: #e31b6d;
}

.contact_medium {
  color: #ffffff;
  text-decoration: none;
  margin-left: 40px;
  font-size: 36px;
}

.contact_medium:hover {
  color: #e31b6d;
}

@media (max-width: 768px) {
  .contact_links {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
  }

  .contact_github {
    font-size: 26px;
    margin-right: 0px;
    margin-bottom: 35px;
  }

  .contact_linkedin {
    font-size: 26px;
    margin-bottom: 35px;
  }

  .contact_medium {
    font-size: 26px;
    margin-left: 0px;
  }
}
