.about {
  height: 100vh;
  padding-top: 100px;
}

.andy_image {
  max-width: 49%;
  margin-left: auto;
  display: flex;
}

.about_text {
  margin-top: 0;
  font-size: 20px;
  text-align: justify;
  color: #888888;
  max-width: 400px;
}

.about_skills {
  font-size: 20px;
  color: #888888;
  max-width: 400px;
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 20px;
  margin-top: 100px;
}

.about_skills {
  border: 1px solid #888888;
  padding: 5px;
}

@media (max-width: 768px) {
  .container {
    display: inline;
  }

  .about_text {
    font-size: 16px;
    margin: 0 15px;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .andy_image {
    margin-bottom: 100px;
  }

  .about_skills {
    margin: 0 15px 0 15px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .about {
    height: 120vh;
  }

  .about_text {
    text-align: justify;
  }

  .about_skills {
    font-size: 20px;
  }

  .andy_image {
    max-width: 50%;
    margin: auto;
    display: flex;
    padding-bottom: 15px;
  }

  .item2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
