.blog {
  height: 240vh;
  padding-top: 100px;
}

.blogs {
  max-width: 1030px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
}

.react_image {
  max-width: 300px;
  margin: 15px;
  border: 2px solid #e7e7eb;
}

.decentral_image {
  margin: 15px;
  max-width: 300px;
  border: 2px solid #e7e7eb;
}

.rails_image {
  margin: 15px;
  max-width: 300px;
  border: 2px solid #e7e7eb;
}

@media (max-width: 768px) {
  .blog {
    height: 120vh;
  }
  .blogs {
    margin-top: 50px;
  }
}
