.portfolio {
  height: 250vh;
  padding-top: 100px;
}

.projects {
  max-width: 1030px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.calculate_image {
  max-width: 300px;
  border: 2px solid #e7e7eb;
  margin: 15px;
}

.room_image {
  max-width: 300px;
  border: 2px solid #e7e7eb;
  margin: 15px;
}

.pets_image {
  height: 150px;
  max-width: 300px;
  border: 2px solid #e7e7eb;
  margin: 15px;
}

.portfolio_github-link {
  text-decoration: none;
  color: #888888;
  margin-right: 10px;
}

.portfolio_github-link:hover {
  color: #e31b6d;
}

.portfolio_demo-link {
  text-decoration: none;
  color: #888888;
  margin-left: 10px;
}

.portfolio_demo-link:hover {
  color: #e31b6d;
}

.portfolio_links {
  text-align: center;
  color: #888888;
}

.portfolio_projects-header {
  text-align: center;
  color: #888888;
  margin-bottom: 0;
  font-size: 22px;
}

.projects_info {
  margin-bottom: 60px;
}

.zoom {
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .portfolio {
    height: 140vh;
  }

  .projects {
    margin-top: 50px;
  }

  .portfolio_projects-header {
    margin-top: 0;
  }
}
